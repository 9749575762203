'use client';

import { usePathname } from 'next/navigation';

import React, { useEffect, useState } from 'react';

import '@STYLE/header.scss';
import '@STYLE/global.scss';

import Link from 'next/link';
import Script from 'next/script';

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const pathname = usePathname();

  // const [location , _] = React.useState({ ...window.location });
  const [color, setColor] = useState('');

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    const paths = pathname.split('/').filter(Boolean);
    const firstDir = paths[0] || '';
    const secondDir = paths[1] || '';
    let newColor = '';

    if (firstDir === 'terminal') {
        if (secondDir === 'subpage1') {
            newColor = '#someColorForSubpage1';
        } else if (secondDir === 'subpage2') {
            newColor = '#someColorForSubpage2';
        } else {
            newColor = '#4e5254';
        }
    } else if (firstDir === 'introduce') {
        newColor = '';
    } else if (firstDir === 'productDetail') {
        newColor = '#4e5254';
    } else if (firstDir === 'inquiry') {
        newColor = '#4e5254';
    } else if (firstDir === 'tabMenuContainer') {
        newColor = 'test4';
    } else if (firstDir === 'csSection') {
        newColor = 'test5';
    }

    setColor(newColor);
}, [pathname]);

  return (
    <header id='headerContainer' className='header' style={{ backgroundColor: color }}>
      <h1 className='blind'>헤더영역 시작</h1>
      <div className='header__inner'>
        <div className='header__inner__contentBox'>
          <div className='logoBox'>
            <h2 className='mainLogo'>
              <Link href='/' className='linkFlex'>
                <p className='header__logo'></p>
                <span>I - ONE PAYMENT</span>
              </Link>
            </h2>
          </div>
          <nav id='nav' className='nav'>
            <ul className='nav__list'>
              <li>
                <Link href='/introduce' className='gnbText'>
                  회사소개
                </Link>
              </li>
              <li>
                <Link href='/#payService' className='gnbText'>
                  서비스소개
                </Link>
              </li>
              <li>
                <Link href='/#serFlow' className='gnbText'>
                  서비스흐름
                </Link>
              </li>
              <li>
                <Link href='/#tabMenuContainer' className='gnbText'>
                  글로벌 결제
                </Link>
              </li>
              <li>
                <Link href='/#csSection' className='gnbText'>
                  고객 지원
                </Link>
              </li>
              {/* <li>
                <Link href='/#contactSection' className='gnbText'>
                  가맹점 관리시스템
                </Link>
              </li> */}
              <div className='nav__list-langBox'>
                <span className='nav__list-langBox-langKor active'>KR</span>
                <span className='nav__list-langBox-langEng'>EN</span>
              </div>
            </ul>
          </nav>
          <button
            className={`mobMenuBtn ${isNavOpen ? 'open' : ''}`}
            onClick={toggleNav}
          >
            <span className='mobMenuBtn__line' />
            <span className='mobMenuBtn__line' />
            <span className='mobMenuBtn__line' />
          </button>
        </div>
      </div>
      <nav id='mobNav' className={`mobNav ${isNavOpen ? 'open' : ''}`}>
        <div className='mobNav__langBox'>
          <span className='mobNav__langBox-langKor active'>KR</span>
          <span className='mobNav__langBox-langEng'>EN</span>
        </div>
        <ul className='mobNav__list'>
          <li className={`${isNavOpen ? 'isNavClose' : ''}`} onClick={closeNav}>
            <Link href='/introduce' className='gnbText'>
              회사소개
            </Link>
          </li>
          <li className={`${isNavOpen ? 'isNavClose' : ''}`} onClick={closeNav}>
            <Link href='/#payService' className='gnbText'>
              서비스소개
            </Link>
          </li>
          <li className={`${isNavOpen ? 'isNavClose' : ''}`} onClick={closeNav}>
            <Link href='/#serFlow' className='gnbText'>
              서비스흐름
            </Link>
          </li>
          <li className={`${isNavOpen ? 'isNavClose' : ''}`} onClick={closeNav}>
            <Link href='/#tabMenuContainer' className='gnbText'>
              글로벌 결제
            </Link>
          </li>
          <li className={`${isNavOpen ? 'isNavClose' : ''}`} onClick={closeNav}>
            <Link href='/#csSection' className='gnbText'>
              고객 지원
            </Link>
          </li>
          {/* <li className={`${isNavOpen ? 'isNavClose' : ''}`} onClick={closeNav}>
            <Link href='/#contactSection' className='gnbText'>
              가맹점 관리시스템
            </Link>
          </li> */}
        </ul>
        {/* <div className='socialGroup'>
          <ul className='socialGroup__list'>
            <li>
              <Link href='https://www.alipay.com/'>알리페이</Link>
            </li>
            <li>
              <Link href='https://www.wechat.com/ko'>위챗페이</Link>
            </li>
            <li>
              <Link href='https://www.icbnet.co.kr/'>ICB</Link>
            </li>
          </ul>
        </div> */}
      </nav>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            document.addEventListener('scroll', () => {
            const header = document.querySelector('.header');
            if (window.scrollY > 80) {
              header.classList.add('scrolled');
            } else {
              header.classList.remove('scrolled');
            }
        });
        `,
        }}
      />
    </header>
  );
}
